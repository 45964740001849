'use client'

import { useState, useCallback, useEffect } from 'react'
import dynamic from 'next/dynamic'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery, Box, AppBar, IconButton, Toolbar, Grid } from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'

// Configurations
import ThemeConfig from '@/configs/theme'

// Types
import type { APISessionCheckTokenData } from '@/types/api/session/check-token'
import type { AppBarProps } from '@/types/components/template/app-bar'

// Helpers
import API from '@/helpers/api'
import { useUserStore } from '@/helpers/store/user'

// Components
const AvatarBarMolecule = dynamic(() => import('@/components/molecules/avatar-bar'))
const DrawerMobileMolecule = dynamic(() => import('@/components/molecules/drawer/mobile'))
const DrawerDesktopMolecule = dynamic(() => import('@/components/molecules/drawer/desktop'))

const AppBarTemplate = (props: AppBarProps) => {
	// Props
	const { lng } = props

	// Variables
	const theme = useTheme()
	const { token, username, setGroup, setStatus, setName, setUsername, setEmail, setPicture, setLocale } = useUserStore()
	const [open, setOpen] = useState<boolean>(true)
	const isMedium = useMediaQuery(theme.breakpoints.up('md'))

	// Callbacks
	const checkToken = useCallback(
		async (token: string) => {
			const result: APISessionCheckTokenData = await API({
				url: '/session/check-token',
				method: 'POST',
				token
			})

			if (result.status && result.data && result.data.item) {
				const item = result.data.item

				if (Object.hasOwn(item, 'group')) setGroup(item.group || null)
				if (Object.hasOwn(item, 'status')) setStatus(item.status || null)
				if (Object.hasOwn(item, 'user_name')) setName(item.user_name || null)
				if (Object.hasOwn(item, 'username')) setUsername(item.username || null)
				if (Object.hasOwn(item, 'email')) setEmail(item.email || null)
				if (Object.hasOwn(item, 'picture')) setPicture(item.picture || null)
				if (Object.hasOwn(item, 'locale')) setLocale(item.locale || null)
			}
		},
		[setGroup, setStatus, setName, setUsername, setEmail, setPicture, setLocale]
	)

	useEffect(() => {
		if (token && !username) checkToken(token)
	}, [token, username, checkToken])

	return (
		<>
			<AppBar
				variant="outlined"
				elevation={0}
				color="inherit"
				sx={{
					bgcolor: theme.palette.background.default,
					borderRight: 0,
					borderTop: 0,
					borderLeft: 0,
					...(!isMedium && { px: { xs: 1, sm: 0.5 } }),
					...(isMedium && {
						right: 'inherit',
						...(!open && { zIndex: theme.zIndex.drawer + 1 })
					})
				}}
			>
				<Toolbar>
					<IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setOpen(!open)} sx={{ mr: 2 }}>
						<MenuIcon />
					</IconButton>

					<Grid sx={{ flexGrow: 1 }}></Grid>
					<AvatarBarMolecule lng={lng} />
				</Toolbar>
			</AppBar>

			<Box component="nav" flexShrink={{ md: 0 }} sx={{ width: { md: open ? ThemeConfig.drawerWidth : theme.spacing(8) } }}>
				{!isMedium && <DrawerMobileMolecule lng={lng} open={open} setOpen={setOpen} />}
				{isMedium && <DrawerDesktopMolecule lng={lng} open={open} setOpen={setOpen} />}
			</Box>
		</>
	)
}

export default AppBarTemplate
