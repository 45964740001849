// Configuration
import APIConfig from '@/configs/api'

// Types
import type { APIProps } from '@/types/helpers/api'

const API = async (props: APIProps) => {
	// Props
	const { url, method, token, revalidate, body } = props

	// Result
	try {
		const response = await fetch(`${APIConfig.url}${url}`, {
			method,
			headers: token ? { Authorization: `Bearer ${token}` } : {},
			...(revalidate ? { next: { revalidate: APIConfig.revalidate } } : {}),
			body
		})

		return await response.json()
	} catch (err) {
		return {}
	}
}

export default API
