// Types
import type { ApplicationProps } from '@/types/configs'

const Config: ApplicationProps = {
	name: process.env.NAME || 'Corleone Dashboard',
	shortName: process.env.SHORT_NAME || 'Corleone',
	siteURL: process.env.SITE_URL || ''
}

export default Config
