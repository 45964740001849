import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import Cookies from 'js-cookie'

// Configurations
import Config from '@/configs'

// Types
import type { UserGroup } from '@/types/api/items/user'
import type { UserStore } from '@/types/helpers/store/user'

// Store
export const useUserStore = create(
	persist<UserStore>(
		set => ({
			token: Cookies.get(`${Config.shortName.toLocaleLowerCase()}-token`),
			setToken: (token: string) => {
				// Change cookie
				if (token !== '') Cookies.set(`${Config.shortName.toLocaleLowerCase()}-token`, token)
				else Cookies.remove(`${Config.shortName.toLocaleLowerCase()}-token`)

				return set({ token })
			},
			setGroup: (group: UserGroup | null) => set({ group }),
			setStatus: (status: number | null) => set({ status }),
			setName: (name: string | null) => set({ name }),
			setUsername: (username: string | null) => set({ username }),
			setEmail: (email: string | null) => set({ email }),
			setPicture: (picture: string | null) => set({ picture }),
			setLocale: (locale: string | null) => set({ locale })
		}),
		{
			name: `${Config.shortName.toLocaleLowerCase()}-user`,
			storage: createJSONStorage(() => localStorage)
		}
	)
)
